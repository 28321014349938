import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Button from '../UI/Button/Button';
import {Link} from 'gatsby';
import RocketIcon from '../../assets/icons/rocket.svg';
import clsx from 'clsx';

// variant:  outlined
// size: small(14px) | medium(16px) | large(21px)
// shadow: primary | primary-dark
// classProp: style class
// textColor: primary | white
// iconColor: primary | white
// background: white

const ContactSalesButton = ({ classProp, variant, shadow, size, textColor, iconColor, background }) => {
  return (
    <Link to="/">
      <Button
        title="Contact sales"
        textColor={textColor ? textColor : ''}
        variant={variant ? variant : ''}
        size={size ? size : ''}
        background={background ? background : ''}
        classProp={classProp ? classProp : ''}
        icon={<RocketIcon className={clsx(
          styles.icon,
          iconColor === 'primary' ? styles.iconColorPrimary : '',
          iconColor === 'white' ? styles.iconColorWhite : ''
        )} />}
        shadow={shadow ? shadow : ''}
      />
    </Link>
  )
};

ContactSalesButton.propTypes = {
  classProp: PropTypes.string,
  variant: PropTypes.string,
  shadow: PropTypes.string,
  background: PropTypes.string,
  textColor: PropTypes.string,
  iconColor: PropTypes.string,
  size: PropTypes.string
};

export default ContactSalesButton;

