import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import clsx from 'clsx';
import Button from '../UI/Button/Button';
import {Link} from 'gatsby';
import {setTryNowModal} from '../../state/actions/mainActions';
import {useDispatch} from 'react-redux';


// shadow: true | false
// classProp: style class

const TryNowButton = ({ classProp, shadow }) => {
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => dispatch(setTryNowModal(true))}
      title="Try now"
      background="primary-dark"
      shadow={shadow ? 'primary-dark' : ''}
      size="medium"
      classProp={clsx( styles.button, classProp ? classProp : '')}
    />
  )
};

TryNowButton.propTypes = {
  shadow: PropTypes.string,
  classProp: PropTypes.string
};

export default TryNowButton;
